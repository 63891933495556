import { useDispatch } from 'react-redux';

import { ChipsContainer, CustomChip, Text, FormHelper, VSpacing } from '@hh.ru/magritte-ui';
import format from 'bloko/common/format';
import { LangTrls, TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Strings from 'Utils/Strings';
import Error from 'src/components/Auction/Common/AuctionControls/Error';
import Replenishment from 'src/components/Auction/Common/AuctionControls/Replenishment';
import { validateAuctionReplenishment } from 'src/components/Auction/Common/AuctionControls/validateAuctionReplenishment';
import { debouncedAuctionControlsEdit, getInputChangeAnalyticsParams } from 'src/components/Auction/utils';
import Currency from 'src/components/Currency';
import FormatMoney from 'src/components/FormatMoney';
import translation from 'src/components/translation';
import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { AuctionFieldName, setProductInfoLoading, updateAuctionControlInfo } from 'src/models/auctionData';
import { CurrencyType } from 'src/models/currencies.types';

const TrlKeys = {
    currency: {
        AZN: 'AZN',
        BYR: 'BYR',
        EUR: 'EUR',
        KZT: 'KZT',
        RUR: 'RUR',
        UAH: 'UAH',
        USD: 'USD',
        UZS: 'UZS',
        GEL: 'GEL',
        KGS: 'KGS',
    },
    placeholder: 'auction.budgetReplenishment.control.placeholder',
    amount: 'auction.budgetReplenishment.amount',
};

const getPlaceholder = (trls: LangTrls, minValue: number, currency: CurrencyType) => {
    const formattedMin = Strings.formatCostInCents(minValue);

    return format(trls[TrlKeys.placeholder], {
        '{0}': formattedMin,
        '{1}': trls[TrlKeys.currency[currency]],
    });
};

const CHIPS_VALUES = ['300000', '500000', '1200000'];

const Control: TranslatedComponent = ({ trls }) => {
    const currency = useSelectorNonNullable(({ auctionData }) => auctionData.currency);
    const replenishmentControl = useSelectorNonNullable(
        ({ auctionData }) => auctionData.controls[AuctionFieldName.Replenishment]
    );
    const budget = useSelectorNonNullable(({ auctionData }) => auctionData.currentVacancyAuctionCampaign?.budget || 0);
    const dispatch = useDispatch();

    const updateReplenishment = (value: string) => {
        const errors = validateAuctionReplenishment({
            minReplenishment: replenishmentControl.minValue,
            replenishment: value,
        });

        const newBudget = String(budget + Number(value));

        if (value !== replenishmentControl.value) {
            dispatch([
                setProductInfoLoading(true),
                updateAuctionControlInfo({
                    fieldName: AuctionFieldName.Replenishment,
                    value,
                    error: errors[AuctionFieldName.Replenishment],
                    showError: false,
                }),
            ]);

            debouncedAuctionControlsEdit(
                dispatch(
                    getInputChangeAnalyticsParams({
                        budget: newBudget,
                    })
                )
            );
        }
    };

    const handleChipClick = (value: string) => () => {
        updateReplenishment(value);
    };

    const handleChange = (value: string) => {
        updateReplenishment(value);
    };

    return (
        <div>
            <Text typography="label-2-regular" style="secondary">
                {trls[TrlKeys.amount]}
            </Text>
            <VSpacing default={8} />
            <Replenishment
                value={replenishmentControl.value}
                onChange={handleChange}
                placeholder={getPlaceholder(trls, replenishmentControl.minValue, currency)}
                postfix={<Currency value={currency} />}
                invalid={replenishmentControl.showError}
                maxLength={7}
                name={AuctionFieldName.Replenishment}
                onBlur={() => {
                    dispatch(
                        updateAuctionControlInfo({
                            fieldName: AuctionFieldName.Replenishment,
                            showError: Boolean(replenishmentControl.error),
                        })
                    );
                }}
                onFocus={() => {
                    dispatch(
                        updateAuctionControlInfo({
                            fieldName: AuctionFieldName.Replenishment,
                            showError: false,
                        })
                    );
                }}
                min={replenishmentControl.minValue}
                data-qa="auction-replenishment-control"
            />
            <FormHelper
                invalid={replenishmentControl.showError}
                errorMessage={<Error error={replenishmentControl.error} />}
                topSpacing={8}
            />
            <VSpacing default={16} />
            <ChipsContainer>
                {CHIPS_VALUES.map((value) => (
                    <CustomChip key={value} Element="button" size="small" onClick={handleChipClick(value)}>
                        <Text typography="subtitle-2-semibold">
                            <FormatMoney currency={currency} inCents>
                                {value}
                            </FormatMoney>
                        </Text>
                    </CustomChip>
                ))}
            </ChipsContainer>
        </div>
    );
};

export default translation(Control);
