import { FC } from 'react';

import useFetchProduct from 'src/components/Auction/hooks/useFetchProduct';
import MoneyInput, { MoneyInputProps } from 'src/components/MoneyInput';

const Replenishment: FC<MoneyInputProps> = ({ value, ...props }) => {
    useFetchProduct({ replenishment: Number(value) });

    return <MoneyInput {...props} value={value} />;
};

export default Replenishment;
