import { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

import styles from './styles.less';

export enum FlexibleRowAlignItems {
    Start = 'start',
    Center = 'center',
}

export enum FlexibleRowJustifyContent {
    Start = 'flex-start',
    Center = 'center',
}

export enum FlexibleRowGap {
    Zero,
    Four,
}

const FlexibleRow: FC<
    {
        alignItems?: FlexibleRowAlignItems;
        justifyContent?: FlexibleRowJustifyContent;
        gap?: FlexibleRowGap;
        disableOnXS?: boolean;
    } & PropsWithChildren
> = ({ children, alignItems, justifyContent, gap, disableOnXS }) => (
    <div
        className={classnames(styles.row, {
            [styles.rowDisableOnXs]: disableOnXS,
            [styles.rowAlignItemsStart]: alignItems === FlexibleRowAlignItems.Start,
            [styles.rowAlignItemsCenter]: alignItems === FlexibleRowAlignItems.Center,
            [styles.rowJustifyContentStart]: justifyContent === FlexibleRowJustifyContent.Start,
            [styles.rowJustifyContentCenter]: justifyContent === FlexibleRowJustifyContent.Center,
            [styles.rowGap0]: gap === FlexibleRowGap.Zero,
            [styles.rowGap4]: gap === FlexibleRowGap.Four,
        })}
    >
        {children}
    </div>
);

export default FlexibleRow;
