import auctionStatisticsAllButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/auction/auction_statistics_all_button_click';
import { Link, Card, Text, VSpacing } from '@hh.ru/magritte-ui';
import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import formatDate from 'Modules/formatDate';
import CampaignStatusLabel from 'src/components/Auction/Common/CampaignStatus/CampaignStatusLabel';
import FieldTip from 'src/components/FieldTip';
import FlexibleRow from 'src/components/FlexibleRow';
import translation from 'src/components/translation';

import styles from './styles.less';

const TrlKeys = {
    status: 'auction.statistics.status',
    responses: 'auction.statistics.responses',
    clicks: 'auction.statistics.clicks',
    conversionToResponse: 'auction.statistics.conversionToResponse',
    startDate: 'auction.statistics.startDate',
    tooltipText: 'auction.statistics.tooltipText',
    all: 'auction.statistics.all',
};

interface StatisticsBannerProps {
    button?: JSX.Element;
}

const StatisticsBanner: TranslatedComponent<StatisticsBannerProps> = ({ trls }) => {
    const vacancyId = useSelectorNonNullable(({ auctionData }) => auctionData.vacancyId || '');
    const clicks = useSelectorNonNullable(({ auctionData }) => auctionData.auctionStatistics.clicks || '—');
    const responses = useSelectorNonNullable(({ auctionData }) => auctionData.auctionStatistics.responses || '—');
    const conversion = useSelectorNonNullable(({ auctionData }) =>
        auctionData.auctionStatistics.conversion ? `${auctionData.auctionStatistics.conversion}%` : '—'
    );
    const startAuctionDate = useSelectorNonNullable(({ auctionData }) =>
        auctionData.auctionStatistics.startAuctionDate
            ? formatDate(auctionData.auctionStatistics.startAuctionDate, 'd MMMM yyyy')
            : '–'
    );
    const linkToConversion = `/employer/statistics/vacancy_conversion/?vacancyId=${vacancyId}`;

    return (
        <Card
            borderRadius={24}
            data-qa="auction-statistics-banner"
            padding={24}
            borderWidth="default"
            stretched
            verticalStretched
            hoverEnabled={false}
        >
            <div className={styles.root}>
                <div className={styles.content}>
                    <div className={styles.status}>
                        <FlexibleRow>
                            <Text typography="label-2-regular">{trls[TrlKeys.status]}</Text>
                            <CampaignStatusLabel dataQaPrefix="edit-auction-modal" />
                        </FlexibleRow>
                        <VSpacing default={20} />
                    </div>
                    <VSpacing default={20} />
                    <FlexibleRow>
                        <Text typography="label-2-regular">{trls[TrlKeys.clicks]}</Text>
                        <Text typography="label-2-regular" data-qa="auction-statistics-banner-clicks">
                            {clicks}
                        </Text>
                    </FlexibleRow>
                    <VSpacing default={20} />
                    <FlexibleRow>
                        <Text typography="label-2-regular">{trls[TrlKeys.responses]}</Text>
                        <Text typography="label-2-regular" data-qa="auction-statistics-banner-responses">
                            {responses}
                        </Text>
                    </FlexibleRow>
                    <VSpacing default={20} />
                    <FlexibleRow>
                        <div className={styles.conversion}>
                            <Text typography="label-2-regular">
                                <FieldTip
                                    tipContent={trls[TrlKeys.tooltipText]}
                                    fieldName={trls[TrlKeys.conversionToResponse]}
                                    withoutVertPadding
                                >
                                    {trls[TrlKeys.conversionToResponse]}
                                </FieldTip>
                            </Text>
                        </div>

                        <Text typography="label-2-regular" data-qa="auction-statistics-banner-conversion">
                            {conversion}
                        </Text>
                    </FlexibleRow>
                    <VSpacing default={20} />
                    <FlexibleRow>
                        <Text typography="label-2-regular">{trls[TrlKeys.startDate]}</Text>
                        <Text typography="label-2-regular" data-qa="auction-statistics-banner-start-date">
                            <div className={styles.date}>{startAuctionDate}</div>
                        </Text>
                    </FlexibleRow>
                </div>
                <VSpacing default={24} />
                <div>
                    <Link
                        data-qa="auction-statistics-banner-link-all-statistics"
                        target="_blank"
                        rel="noopener noreferrer"
                        Element="a"
                        href={linkToConversion}
                        onClick={() => auctionStatisticsAllButtonClick({ vacancyId })}
                    >
                        {trls[TrlKeys.all]}
                    </Link>
                </div>
            </div>
        </Card>
    );
};

export default translation(StatisticsBanner);
