import { useDispatch } from 'react-redux';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import auctionRecommendedBudgetHintButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/auction/auction_recommended_budget_hint_button_click';
import auctionRecommendedBudgetHintElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/auction/auction_recommended_budget_hint_element_shown';
import { VSpacing, Card, Link, Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import { debouncedAuctionControlsEdit, getInputChangeAnalyticsParams } from 'src/components/Auction/utils';
import FormatMoney from 'src/components/FormatMoney';
import translation from 'src/components/translation';
import { useSelectorNonNullable, useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    hint: 'auction.controls.hint.recommendedBudget',
};

interface HintProps {
    bid: string;
    budget: string;
    initialBid: string;
    initialBudget: string;
    onClick: (recommendedBudget: number) => void;
}

const Hint: TranslatedComponent<HintProps> = ({ bid, budget, initialBid, initialBudget, onClick, trls }) => {
    const currency = useSelectorNonNullable(({ auctionData }) => auctionData.currency);
    const vacancyCreateDraftId = useSelector(({ vacancyCreateDraftId }) => vacancyCreateDraftId);
    const vacancyId = useSelectorNonNullable(({ auctionData }) => auctionData.vacancyId);

    const dispatch = useDispatch();

    // LPV вакансия — вакансия, набирающая < 5 откликов.
    // Конфигурация аукциона считается оптимальной, если позволяет набрать не меньше 5 откликов
    // при средней конверсии клика в отклик 5%.
    const recommendedBudget = Number(bid) * 100;

    if (Number(budget) >= recommendedBudget) {
        return null;
    }

    return (
        <ElementShownAnchor
            fn={auctionRecommendedBudgetHintElementShown}
            vacancyId={vacancyId}
            bid={bid}
            budget={budget}
            draftId={vacancyCreateDraftId}
            initialBid={initialBid}
            initialBudget={initialBudget}
        >
            <Card borderRadius={24} padding={24} style="warning-secondary" stretched hoverEnabled={false}>
                <Text typography="paragraph-2-regular">
                    {formatToReactComponent(trls[TrlKeys.hint], {
                        '{0}': (
                            <Link
                                Element="button"
                                inline
                                typography="paragraph-2-regular"
                                onClick={() => {
                                    auctionRecommendedBudgetHintButtonClick({
                                        vacancyId,
                                        bid,
                                        budget,
                                        draftId: vacancyCreateDraftId,
                                        initialBid,
                                        initialBudget,
                                    });
                                    onClick(recommendedBudget);
                                    debouncedAuctionControlsEdit(
                                        dispatch(
                                            getInputChangeAnalyticsParams({
                                                budget: String(recommendedBudget),
                                            })
                                        )
                                    );
                                }}
                            >
                                <FormatMoney currency={currency} inCents>
                                    {recommendedBudget}
                                </FormatMoney>
                            </Link>
                        ),
                    })}
                </Text>
            </Card>
            <VSpacing default={24} />
        </ElementShownAnchor>
    );
};

export default translation(Hint);
