import { FC } from 'react';

import AuctionInput, { AuctionInputProps } from 'src/components/Auction/Common/AuctionControls/AuctionInput';
import useFetchProduct from 'src/components/Auction/hooks/useFetchProduct';
import { useSelectorNonNullable } from 'src/hooks/useSelector';

const BUDGET_STEP = 10000;

interface BudgetProps extends Omit<AuctionInputProps, 'step'> {
    onPlusOrMinusClick?: (budget: string) => void;
    minusDataQa?: string;
    plusDataQa?: string;
    inversed?: boolean;
}
const Budget: FC<BudgetProps> = ({ value, onPlusOrMinusClick, inversed, ...props }) => {
    const numericValue = Number(value);
    const currentVacancyAuctionCampaign = useSelectorNonNullable(
        ({ auctionData }) => auctionData.currentVacancyAuctionCampaign
    );
    const actualBudget = currentVacancyAuctionCampaign
        ? numericValue - currentVacancyAuctionCampaign.budget
        : numericValue;

    useFetchProduct({ replenishment: actualBudget });
    return (
        <AuctionInput
            {...props}
            value={value}
            step={BUDGET_STEP}
            onPlusOrMinusClick={onPlusOrMinusClick}
            inversed={inversed}
        />
    );
};

export default Budget;
